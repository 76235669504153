const app = { version: '2.02', releasedOn: '2022-09-29', name: 'Udhaar Khata', email: 'narendra.webhelp@gmail.com' }
const key = {
    token: 'token.',
    permission: 'permission.',
    userDetail: 'user-detail.',
    version: 'version.',

    contactList: 'contact',
    transactionList: 'transaction',
    settings: 'settings'
}

const permission = {
    public: "0",
};

const component = {
    transaction: { url: '/', title: 'Transactions', permission: permission.public },
    userTransaction: { url: '/transactions/user/:id', title: 'User Transactions', permission: permission.public },
    contact: { url: '/contact', title: 'Contacts', permission: permission.public },
    report: { url: '/report', title: 'Reports', permission: permission.public },
    setting: { url: '/setting', title: 'Settings', permission: permission.public },
    privacyPolicy: { url: '/privacy-policy', title: 'Privacy Policy', permission: permission.public },
};

const transactionType = {
    borrowed: { id: 1, name: 'Borrowed' },
    returned: { id: 2, name: 'Returned' }
};

const currency = [
    { id: '$', name: 'USD' },
    { id: '€', name: 'EUR' },
    { id: '¥', name: 'JPY' },
    { id: '£', name: 'GBP' },
    { id: '₹', name: 'INR', default: true },
    { id: '₽', name: 'RUB' },
    // { id: '₺', name: 'TRY' },
    // { id: '฿', name: 'THB' },
    // { id: '₪', name: 'ILS' },
    // { id: '₱', name: 'PHP' },
    { id: '৳', name: 'BDT' },
    { id: '¥', name: 'CNY' },
    { id: 'Rs', name: 'LKR' },
]

const language = [
    { id: 'en', name: 'English', default: true },
    { id: 'hi', name: 'Hindi' },
]

const message = {
    recordSynched: 'Record was synched successfully',
    photoAdded: 'Photo was added successfully',
    recordAdded: 'Record was added successfully',
    recordUpdated: 'Changes were saved successfully',
    recordDeleted: 'Records was deleted successfully',
    documentAdded: 'Documents were addedd successfully',
    messageAdded: 'Message was posted successfully',
    emailSent: 'Email has been sent successfully to the recipient',
};

const formatting = {
    dateFormat: 'DD MMM YYYY',
    // timeFormat: 'hh:mm:ss A',
    // monthFormat: 'MMM YYYY',
    dateTimeFormat: 'DD MMM YYYY hh:mm A',
    // shortDateTimeFormat: 'DD MM YY hh:mm A',

    // usDateTimeFormatWithSeconds: 'ddd MM/DD/YYYY hh:mm:ss A',
    // usDateTimeFormat: 'ddd MM/DD/YYYY hh:mm A',
    // usDateFormat: 'MM/DD/YYYY',
    // usDateWithDayFormat: 'ddd MM/DD/YYYY',
    // usShortDateTimeFormat: 'MM/DD/YY hh:mm A',
};

const obj = { component, key, permission, message, formatting, app, transactionType, currency, language };

export default obj;