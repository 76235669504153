import { useRecoilState } from 'recoil';
import { useContext, useState } from 'react';
import moment from 'moment';

import constant from '../../constant';

import { onValidateForm, setHTMLFontSize } from '../../utils';
import { updateDetail } from '../../action/SettingsAction';
import { languageAtom, currencyAtom, fontSizeAtom } from './../../atom/SettingsAtom';
import { ToastContext } from '../../context/ToastContext';

interface IPropType {
    detail?: any,
    onCancel?: any,
    onSubmitComplete?: any,
}

function AddDetail(props: IPropType) {
    const { detail, onSubmitComplete } = props;
    const toastContext: any = useContext(ToastContext);

    const [state, setState] = useState({
        currency: { name: 'currency', value: detail ? detail.currency : '', required: true, error: '', options: constant.currency },
        language: { name: 'language', value: detail ? detail.language : '', required: true, error: '', options: constant.language },
        fontSize: { name: 'fontSize', value: detail ? detail.fontSize : '', required: true, error: '', options: constant.language },
    });

    const [_currency, _setCurrency] = useRecoilState(currencyAtom);
    const [_language, _setLanguage] = useRecoilState(languageAtom);
    const [_fontSize, _setFontSize] = useRecoilState(fontSizeAtom);

    const { currency, language, fontSize } = state;

    const onChange = (e: any) => {
        const { name, value } = e.target;
        setState((prevState: any) => ({
            ...prevState,
            [name]: { ...prevState[name], value }
        }));

        // onSubmit()
    }

    const onSubmit = (e: any) => {
        e.preventDefault();
        if (onValidateForm(state, setState)) {

            const { currency, language, fontSize } = state;
            const model = {
                currency: currency.value,
                language: language.value,
                fontSize: fontSize.value,
                modifiedOn: moment().format()
            }

            updateDetail(model).then((res: any) => {
                if (res.error) {
                    toastContext.toast.error(res.error);
                } else {
                    toastContext.toast.success(constant.message.recordUpdated);

                    _setCurrency(model.currency);
                    _setLanguage(model.language);
                    _setFontSize(model.fontSize);

                    setHTMLFontSize(model.fontSize);
                    onSubmitComplete(res.result);
                }
            })
        }
    }

    return (
        <form onSubmit={onSubmit}>
            <div className="form-group">
                <label>Language *</label>
                <select
                    name={language.name}
                    onChange={onChange}
                    value={language.value}
                    className={language.error.length === 0 ? "form-control" : "form-control is-invalid"}
                >
                    {
                        language.options.map((item: any, index: number) => {
                            return <option key={index} value={item.id}>{item.name}</option>
                        })
                    }
                </select>
            </div>
            <div className="form-group">
                <label>Currency *</label>
                <select
                    name={currency.name}
                    onChange={onChange}
                    value={currency.value}
                    className={currency.error.length === 0 ? "form-control" : "form-control is-invalid"}
                >
                    {
                        currency.options.map((item: any, index: number) => {
                            return <option key={index} value={item.id}>{item.name}</option>
                        })
                    }
                </select>
            </div>

            <div className="form-group">
                <label>Font Size *</label>
                <input
                    name={fontSize.name}
                    onChange={onChange}
                    value={fontSize.value}
                    type="range" className="custom-range" min="14" max="18" />
                <div>
                    Preview
                    <p style={{ fontSize: `${fontSize.value}px` }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>
            <div>
                <button type="submit" className="btn btn-primary btn-block">Save Changes</button>
            </div>
        </form>
    )
}

export default AddDetail;