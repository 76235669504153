import localforage from 'localforage';
import constant from '../constant';

export const getList = async () => {
    try {
        let list: any = await localforage.getItem(constant.key.contactList);
        let finalList: any = [];
        if (list) {
            finalList = list;
        }
        return { error: null, result: finalList }
    } catch (err: any) {
        return { error: err.message, result: null }
    }
}

export const getDetail = async (id: any) => {
    try {
        let list: any = await localforage.getItem(constant.key.contactList);
        const detail = list.find((i: any) => i.id === id);
        return { error: null, result: detail }
    } catch (err: any) {
        return { error: err.message, result: null }
    }
}

export const addDetail = async (model: any) => {
    try {
        let list: any = await localforage.getItem(constant.key.contactList);
        if (list) {
            list.push(model);
        } else {
            list = [model];
        }
        await localforage.setItem(constant.key.contactList, list);
        return { error: null, result: model }
    } catch (err: any) {
        return { error: err.message, result: null }
    }
}

export const updateDetail = async (model: any) => {
    try {
        let list: any = await localforage.getItem(constant.key.contactList);
        list.forEach((element: any, index: number, arr: any) => {
            if (element.id === model.id) {
                const obj = { ...arr[index], ...model };
                arr[index] = obj;
            }
        });
        await localforage.setItem(constant.key.contactList, list);
        return { error: null, result: model }
    } catch (err: any) {
        return { error: err.message, result: null }
    }
}

export const deleteDetail = async (id: string) => {
    try {
        let list: any = await localforage.getItem(constant.key.contactList);
        list.forEach((element: any, index: number, arr: any) => {
            if (element.id === id) {
                arr[index].deleted = true;
            }
        });
        await localforage.setItem(constant.key.contactList, list);
        return { error: null, result: 'success' }
    } catch (err: any) {
        return { error: err.message, result: 'failed' }
    }
}

export const updateBalance = async (id: string) => {
    try {
        let transactionList: any = await localforage.getItem(constant.key.transactionList);
        const filteredList = transactionList.filter((i: any) => i.contactId === id);
        const totalBorrowed = filteredList.filter((i: any) => i.transactionTypeId === constant.transactionType.borrowed.id);
        const totalReturned = filteredList.filter((i: any) => i.transactionTypeId === constant.transactionType.returned.id);
        
        const totalBorrowedAmount = totalBorrowed.reduce((acc: any, item: any) => acc + parseFloat(item.amount), 0);
        const totalReturnedAmount = totalReturned.reduce((acc: any, item: any) => acc + parseFloat(item.amount), 0);

        let constactlist: any = await localforage.getItem(constant.key.contactList);
        constactlist.forEach((element: any, index: number, arr: any) => {
            if (element.id === id) {
                arr[index].borrowed = totalBorrowedAmount;
                arr[index].returned = totalReturnedAmount;
            }
        });
        await localforage.setItem(constant.key.contactList, constactlist);

        return { error: null, result: 'success' }
    } catch (err: any) {
        return { error: err.message, result: 'failed' }
    }
}