import Header from "../../component/common/Header";

function NoInternet() {
    return (
        <div className="container-fluid">
            <Header />
            <div className="row">
                <div className="col-12">
                    <div style={{ display: 'flex', height: '100vh', flexDirection: 'column', justifyContent: 'center' }} className="">
                        <h2 className="mb-0 font-weight-bold">No Internet</h2>
                        <p>need to connect to internet in order to work. Please connect and try again.</p>
                    </div>
                </div>
            </div >
        </div>
    )
}

export default NoInternet;