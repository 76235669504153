import localforage from 'localforage';
import constant from '../constant';

export const getList = async () => {
    try {
        let list: any = await localforage.getItem(constant.key.transactionList);
        let finalList: any = [];
        if (list) {
            finalList = list;
        }
        const contactList: any = await localforage.getItem(constant.key.contactList);
        finalList.forEach((element: any, index: number, arr: any) => {
            const contactDetail = contactList.find((i: any) => i.id === element.contactId);
            if (contactDetail) {
                arr[index].contactName = contactDetail.name;
                arr[index].contactDescription = contactDetail.description;
            }
        });

        return { error: null, result: finalList }
    } catch (err: any) {
        return { error: err.message, result: null }
    }
}

export const getUserTransactionList = async (contactId: string) => {
    try {
        let list: any = await localforage.getItem(constant.key.transactionList);
        let finalList: any = [];
        if (list) {
            finalList = list.filter((i: any) => i.contactId === contactId);
        }
        
        const contactList: any = await localforage.getItem(constant.key.contactList);
        finalList.forEach((element: any, index: number, arr: any) => {
            const contactDetail = contactList.find((i: any) => i.id === element.contactId);
            if (contactDetail) {
                arr[index].contactName = contactDetail.name;
                arr[index].contactDescription = contactDetail.description;
            }
        });

        return { error: null, result: finalList }
    } catch (err: any) {
        return { error: err.message, result: null }
    }
}

export const getDetail = async (id: any) => {
    try {
        let list: any = await localforage.getItem(constant.key.transactionList);
        const detail = list.find((i: any) => i.id === id);
        return { error: null, result: detail }
    } catch (err: any) {
        return { error: err.message, result: null }
    }
}

export const addDetail = async (model: any) => {
    try {
        let list: any = await localforage.getItem(constant.key.transactionList);
        if (list) {
            list.push(model);
        } else {
            list = [model];
        }
        await localforage.setItem(constant.key.transactionList, list);
        return { error: null, result: model }
    } catch (err: any) {
        return { error: err.message, result: null }
    }
}

export const updateDetail = async (model: any) => {
    try {
        let list: any = await localforage.getItem(constant.key.transactionList);
        list.forEach((element: any, index: number, arr: any) => {
            if (element.id === model.id) {
                const obj = { ...arr[index], ...model };
                arr[index] = obj;
            }
        });
        await localforage.setItem(constant.key.transactionList, list);
        return { error: null, result: model }
    } catch (err: any) {
        return { error: err.message, result: null }
    }
}

export const deleteDetail = async (id: string) => {
    try {
        let list: any = await localforage.getItem(constant.key.transactionList);
        list.forEach((element: any, index: number, arr: any) => {
            if (element.id === id) {
                arr[index].deleted = true;
            }
        });
        await localforage.setItem(constant.key.transactionList, list);
        return { error: null, result: 'success' }
    } catch (err: any) {
        return { error: err.message, result: 'failed' }
    }
}