import React, { useState } from 'react';
import { getDetail } from '../../action/ContactAction';

import ModalWindow from '../common/ModalWindow';
import AddDetailForm from './AddDetailForm';

interface IPropType {
    id: string,
    css: string,
    onSubmitComplete?: any,
    children: any
}

function EditDetailButton(props: IPropType) {
    const onShowModal = () => {
        setShowModal(true);
        getDetail(props.id).then((res: any) => {
            if (res.error) {
                alert(res.error);
            } else {
                setDetail(res.result);
            }
        })
    }

    const onHideModal = () => {
        setShowModal(false);
    }

    const onSubmitComplete = (detail: any) => {
        onHideModal();
        props.onSubmitComplete(detail);
    }

    const [showModal, setShowModal] = useState(false);
    const [detail, setDetail] = useState(undefined);
    return (
        <React.Fragment>
            {
                showModal && detail && <ModalWindow
                    css="modal-md"
                    title="Contact Detail"
                    backdrop={true}
                    onCancel={onHideModal}
                >
                    <AddDetailForm
                        detail={detail}
                        onSubmitComplete={onSubmitComplete}
                        onCancel={onHideModal}
                    />
                </ModalWindow>
            }
            <button className={props.css} onClick={onShowModal}>
                {props.children}
            </button>
        </React.Fragment>
    )
}

export default EditDetailButton;