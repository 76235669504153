import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import ReactGA from 'react-ga4';

import Header from "../../component/common/Header";
import CardView from "../../component/contact/CardView";
import FloatingAddButton from "../../component/contact/FloatingAddButton";

import { getList } from "../../action/ContactAction";
import { languageAtom } from "../../atom/SettingsAtom";
import { getLocale } from "../../utils";
import constant from "../../constant";

function List() {
    const [list, setList]: any = useState([]);
    const language = useRecoilValue(languageAtom);
    const locale = getLocale(language);

    useEffect(() => {
        document.title = constant.component.contact.title;
        ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);
        ReactGA.pageview(window.location.pathname + window.location.search);

        onLoadList();
    }, [])

    return (
        <div className="container-fluid">
            <Header />
            <FloatingAddButton onSubmitComplete={() => onLoadList()} />
            <div className="">
                <h5 className="mt-3">{locale.lbl_contacts}</h5>
                <hr />
                <div className="row mb-10">
                    <div className="col-md-12">
                        {
                            list.length === 0 && <div className="text-center">
                                {locale.lbl_noRecordsFound}
                            </div>
                        }
                        {
                            list.map((item: any, index: number) => {
                                return <div key={index}>
                                    <CardView css="mb-3" detail={item} showFooter={true} />
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
        </div >
    )

    function onLoadList() {
        getList().then((res: any) => {
            if (res.error) {

            } else {
                setList(res.result);
            }
        })
    }
}

export default List;