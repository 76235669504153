import React, { useState } from 'react';

import ModalWindow from '../common/ModalWindow';
import AddDetailForm from './AddDetailForm';

interface IPropType {
    onSubmitComplete: any,
}

function FloatingAddButton(props: IPropType) {

    const onShowModal = () => {
        setShowModal(true);
    }

    const onHideModal = () => {
        setShowModal(false);
    }

    const onSubmitComplete = (detail: any) => {
        onHideModal();
        props.onSubmitComplete(detail);
    }

    const [showModal, setShowModal] = useState(false);
    return (
        <React.Fragment>
            {
                showModal && <ModalWindow
                    css="modal-md"
                    title="Contact Detail"
                    backdrop={true}
                    onCancel={onHideModal}
                >
                    <AddDetailForm
                        onSubmitComplete={onSubmitComplete}
                        onCancel={onHideModal}
                    />
                </ModalWindow>
            }
            <label className="fab-btn" onClick={onShowModal}>
                <span>+</span>
            </label>
        </React.Fragment>
    )
}

export default FloatingAddButton;