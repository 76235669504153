import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const ToastContext = React.createContext({});

export const ToastProvider = (props: any) => {
    return (
        <ToastContext.Provider value={{ toast }}>
            {props.children}
            <ToastContainer position="top-center" />
        </ToastContext.Provider>
    )
}

// const WithToastContext = (Component: any) => {
//     return (props: any) => (
//         <ToastContext.Consumer>
//             {(value: any) => <Component {...props} context={value} />}
//         </ToastContext.Consumer>
//     )
// }

// export default WithToastContext;