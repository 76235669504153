import moment from "moment";
import { useEffect, useState } from "react";
import ReactGA from 'react-ga4';

import Header from "../../component/common/Header";
import AddDetailForm from "../../component/settings/AddDetailForm";
import constant from "../../constant";

import { getDetail } from "../../action/SettingsAction";

function Settings() {
    const [detail, setDetail]: any = useState(undefined);

    useEffect(() => {
        document.title = constant.component.setting.title;
        ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);
        ReactGA.pageview(window.location.pathname + window.location.search);

        getDetail().then((res: any) => {
            if (res.error) {
                alert(res.error);
            } else {
                setDetail(res.result);
            }
        })
    }, [])
    return (
        <div className="container-fluid">
            <Header />
            <div className="row mt-3">
                <div className="col-md-12 mb-10">

                    <div className="card mb-3">
                        <div className="card-header">
                            Settings
                        </div>
                        <div className="card-body p-3">
                            {
                                detail && <AddDetailForm detail={detail} onSubmitComplete={() => { }} />
                            }
                        </div>
                    </div>

                    <div className="card mb-3">
                        <div className="card-header">
                            Contact 👋
                        </div>
                        <div className="card-body p-3">
                            <p className="text-justify">
                                Hi,<br />
                                Thank you for using our App. If you want to share a feedback or ask something, you can mail me on below email.
                                {/* You can email me on below email if you have any queries or just want to say Hi!!!<br /> */}
                            </p>
                            <a target="_blank" rel="noreferrer" href={`mailto:${constant.app.email}?subject=${constant.app.name}`}>{constant.app.email}</a>
                        </div>
                    </div>

                    <div className="card mb-3">
                        <div className="card-header">
                            App
                        </div>
                        <div className="card-body p-3">
                            Version: {constant.app.version}<br />
                            Released On: {moment(constant.app.releasedOn).format(constant.formatting.dateFormat)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Settings;