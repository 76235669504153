import uniqid from 'uniqid';
import moment from 'moment';
import { useContext, useState } from 'react';
import { onValidateForm } from '../../utils';
import { addDetail, updateBalance, updateDetail } from '../../action/ContactAction';
import { ToastContext } from '../../context/ToastContext';
import constant from '../../constant';

interface IPropType {
    detail?: any,
    onCancel?: any,
    onSubmitComplete?: any,
}

function AddDetail(props: IPropType) {
    const { detail, onSubmitComplete } = props;
    const toastContext: any = useContext(ToastContext);

    const [state, setState] = useState({
        id: detail ? detail.id : uniqid(),
        name: { name: 'name', value: detail ? detail.name : '', required: true, error: '', placeholder: 'Name of the user' },
        description: { name: 'description', value: detail ? detail.description : '', required: false, error: '', placeholder: 'Additional detail of the user. will be used during search' },
    });
    const { name, description } = state;

    const onChange = (e: any) => {
        const { name, value } = e.target;
        setState((prevState: any) => ({
            ...prevState,
            [name]: { ...prevState[name], value }
        }));
    }

    const onSubmit = (e: any) => {
        e.preventDefault();
        if (onValidateForm(state, setState)) {

            const { id, name, description } = state;
            if (detail) {
                const model = {
                    id,
                    name: name.value,
                    description: description.value,
                    modifiedOn: moment().format()
                }

                updateDetail(model).then((res: any) => {
                    if (res.error) {
                        toastContext.toast.error(res.error);
                    } else {
                        toastContext.toast.success(constant.message.recordUpdated);
                        updateBalance(id);
                        onSubmitComplete(res.result);
                    }
                })
            } else {
                const model = {
                    id,
                    name: name.value,
                    description: description.value,
                    createdOn: moment().format(),
                    borrowed: 0,
                    returned: 0,
                }
                addDetail(model).then((res: any) => {
                    if (res.error) {
                        alert(res.error);
                    } else {
                        onSubmitComplete(res.result);
                        updateBalance(id);
                    }
                })
            }

        }
    }
    return (
        <form onSubmit={onSubmit}>
            <div className="form-group">
                <label>Name *</label>
                <input type="text"
                    name={name.name}
                    className={name.error.length > 0 ? "form-control is-invalid" : "form-control"}
                    placeholder={name.placeholder}
                    value={name.value}
                    onChange={onChange}
                />
            </div>
            <div className="form-group">
                <label>Description</label>
                <textarea rows={5}
                    name={description.name}
                    className={description.error.length > 0 ? "form-control is-invalid" : "form-control"}
                    placeholder={description.placeholder}
                    value={description.value}
                    onChange={onChange}
                />
            </div>
            <div>
                <button type="submit" className="btn btn-primary btn-block">Save Changes</button>
            </div>
        </form>
    )
}

export default AddDetail;