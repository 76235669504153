import { atom } from 'recoil';

export const currencyAtom = atom({
    key: 'currency',
    default: '',
});

export const languageAtom = atom({
    key: 'language',
    default: '',
});

export const fontSizeAtom = atom({
    key: 'fontSize',
    default: '16',
});

export const themeAtom = atom({
    key: 'theme',
    default: '',
});