function FourZeroFour() {
    document.title = '404 - Page Not Found';
    const onGoBack = () => {
        window.history.back();
    }
    return (
        <div className="container-fluid">
            <div className="row mt-5">
                <div className="col-12 text-center">
                    <h1 className="font-weight-bold">404</h1>
                    <p>The page you are looking for cannot be found. Click <button className="btn btn-link p-0" onClick={() => onGoBack()}>here</button> to go back</p>
                </div>
            </div>

        </div>
    );
}

export default FourZeroFour;