import { useContext, useEffect, useState } from 'react';
import uniqid from 'uniqid';
import moment from 'moment';
import { NumericFormat } from 'react-number-format';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import { onSetOptions, onSetValue, onValidateForm } from '../../utils';
import { addDetail, updateDetail } from '../../action/TransactionAction';
import constant from '../../constant';
import { getList, updateBalance } from '../../action/ContactAction';
import { currencyAtom } from "../../atom/SettingsAtom";
import { useRecoilValue } from "recoil";
import { ToastContext } from '../../context/ToastContext';

interface IPropType {
    detail?: any,
    onCancel?: any,
    onSubmitComplete?: any,
}

function AddDetail(props: IPropType) {
    const currencySymbol = useRecoilValue(currencyAtom);

    const { detail, onSubmitComplete } = props;
    const toastContext: any = useContext(ToastContext);

    const transactionTypeOptions = [constant.transactionType.borrowed, constant.transactionType.returned];
    const [state, setState] = useState({
        id: detail ? detail.id : uniqid(),
        amount: { name: 'amount', value: detail ? detail.amount : '', required: true, error: '', placeholder: 'Amount Borrowed (CR)/Returned (DR)' },
        createdOn: { name: 'createdOn', value: detail ? moment(detail.createdOn).format('YYYY-MM-DD HH:mm:ss') : moment().format('YYYY-MM-DD HH:mm:ss'), required: true, error: '', placeholder: 'Dated' },
        transactionTypeId: { name: 'transactionTypeId', value: detail ? `${detail.transactionTypeId}` : `${constant.transactionType.borrowed.id}`, required: true, error: '', placeholder: 'Amount Borrowed/Returned', options: transactionTypeOptions },
        contactId: { name: 'contactId', value: detail ? [{ id: detail.contactId }] : [], required: true, error: '', placeholder: 'Name of the user', options: [] },
        description: { name: 'description', value: detail ? detail.description : '', required: false, error: '', placeholder: 'Additional detail of the user. will be used during search' },
    });
    const { amount, createdOn, transactionTypeId, contactId, description } = state;

    useEffect(() => {
        getList().then((res: any) => {
            if (detail) {
                const contactDetail = res.result.find((i: any) => i.id === detail.contactId);
                onSetValue(setState, contactId.name, [contactDetail])
            }

            onSetOptions(setState, contactId.name, res.result);
        })
    }, []);

    return (
        <form onSubmit={onSubmit}>
            <div className="form-group">
                <label>Amount *</label>
                <NumericFormat
                    name={amount.name}
                    className={amount.error.length > 0 ? "form-control is-invalid" : "form-control"}
                    placeholder={amount.placeholder}
                    value={amount.value}
                    valueIsNumericString={true}
                    onValueChange={(e: any) => { onChange({ target: { name: amount.name, value: e.value } }) }}
                    thousandSeparator=","
                    allowNegative={false}
                    decimalScale={2}
                    prefix={`${currencySymbol} `}
                />
            </div>
            <div className="form-group">
                <label>Dated *</label>
                <input type="datetime-local"
                    name={createdOn.name}
                    className={createdOn.error.length > 0 ? "form-control is-invalid" : "form-control"}
                    placeholder={createdOn.placeholder}
                    value={createdOn.value}
                    onChange={onChange}
                />
            </div>
            <div className="form-group">
                <label>Contact *</label>
                <Typeahead
                    id="ta-exchange-id"
                    allowNew={false}
                    labelKey={(e: any) => `${e.name}`}
                    name={contactId.name}
                    selected={contactId.value}
                    multiple={false}
                    options={contactId.options}
                    onChange={(e: any) => onTypeaheadChange(contactId.name, e)}
                    placeholder=""
                    isInvalid={contactId.error.length > 0} />
            </div>
            <div className="form-group">
                <label>Transaction Type *</label><br />
                <div className="custom-control custom-radio custom-control-inline">
                    <input checked={transactionTypeId.value === `${constant.transactionType.borrowed.id}`} name={transactionTypeId.name} type="radio" id="customRadioInline1" className="custom-control-input" value={`${constant.transactionType.borrowed.id}`} onChange={onChange} />
                    <label className="custom-control-label" htmlFor="customRadioInline1">Borrowed (CR)</label>
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                    <input checked={transactionTypeId.value === `${constant.transactionType.returned.id}`} name={transactionTypeId.name} type="radio" id="customRadioInline2" className="custom-control-input" value={`${constant.transactionType.returned.id}`} onChange={onChange} />
                    <label className="custom-control-label" htmlFor="customRadioInline2">Returned (DR)</label>
                </div>
            </div>
            <div className="form-group">
                <label>Description</label>
                <textarea rows={5}
                    name={description.name}
                    className={description.error.length > 0 ? "form-control is-invalid" : "form-control"}
                    placeholder={description.placeholder}
                    value={description.value}
                    onChange={onChange}
                />
            </div>
            <div>
                <button type="submit" className="btn btn-primary btn-block">Save Changes</button>
            </div>
        </form>
    )

    function onChange(e: any) {
        const { name, value } = e.target;
        setState((prevState: any) => ({
            ...prevState,
            [name]: { ...prevState[name], value }
        }));
    }

    function onTypeaheadChange(name: string, e: any) {
        let value = e;
        if (e.length > 0 && e[0].customOption) {
            value = [{ name: e[0].name }];
        }
        onChange({ target: { name, value } });
    }

    function onSubmit(e: any) {
        e.preventDefault();
        if (onValidateForm(state, setState)) {

            const { id, amount, createdOn, transactionTypeId, contactId, description }: any = state;
            const contactIdValue = contactId.value[0].id;
            if (detail) {
                const model = {
                    id,
                    amount: parseFloat(amount.value),
                    transactionTypeId: parseFloat(transactionTypeId.value),
                    contactId: contactIdValue,
                    description: description.value,
                    createdOn: moment(createdOn.value).format(),
                    createdOnUnix: moment(createdOn.value).unix(),

                    modifiedOn: moment().format(),
                    modifiedOnUnix: moment().unix(),
                }
                updateDetail(model).then(async (res: any) => {
                    if (res.error) {
                        toastContext.toast.error(res.error);
                    } else {
                        toastContext.toast.success(constant.message.recordUpdated);
                        await updateBalance(contactIdValue);
                        onSubmitComplete(res.result);
                    }
                })
            } else {
                const model = {
                    id,
                    amount: parseFloat(amount.value),
                    transactionTypeId: parseFloat(transactionTypeId.value),
                    contactId: contactIdValue,
                    description: description.value,
                    createdOn: moment(createdOn.value).format(),
                    createdOnUnix: moment(createdOn.value).unix(),

                    modifiedOn: moment().format(),
                    modifiedOnUnix: moment().unix(),
                }
                addDetail(model).then((res: any) => {
                    if (res.error) {
                        toastContext.toast.error(res.error);
                    } else {
                        toastContext.toast.success(constant.message.recordUpdated);
                        updateBalance(contactIdValue);
                        onSubmitComplete(res.result);
                    }
                })
            }
        }
    }
}

export default AddDetail;