import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import constant from './constant'
//#region components

import FourZeroFour from './container/misc/FourZeroFour';
import ContactList from './container/contact/List';
import TransactionList from './container/transaction/List';
import UserTransactionList from './container/transaction/UserTransaction';
import Settings from './container/misc/Settings';
import Reports from './container/misc/Reports';
import PrivacyPolicy from './container/misc/PrivacyPolicy';

import { getDetail } from './action/SettingsAction';
import { useRecoilState } from 'recoil';
import { currencyAtom, fontSizeAtom, languageAtom } from './atom/SettingsAtom';
import { setHTMLFontSize } from './utils';
//#endregion components

function Router() {
    const [language, setLanguage] = useRecoilState(languageAtom);
    const [currency, setCurrency] = useRecoilState(currencyAtom);
    const [fontSize, setFontSize] = useRecoilState(fontSizeAtom);

    useEffect(() => {
        const func = async () => {
            const detail: any = await getDetail();
            setLanguage(detail.result.language);
            setCurrency(detail.result.currency);
            setFontSize(detail.result.fontSize);
            setHTMLFontSize(detail.result.fontSize);
        }
        func();
    }, [])

    return (
        <React.Fragment>
            {
                language === '' && currency === '' ? <>Loading...</>
                    : <Routes>
                        <Route path={constant.component.transaction.url} element={<TransactionList />} />
                        <Route path={constant.component.userTransaction.url} element={<UserTransactionList />} />

                        <Route path={constant.component.contact.url} element={<ContactList />} />
                        <Route path={constant.component.report.url} element={<Reports />} />
                        <Route path={constant.component.setting.url} element={<Settings />} />
                        <Route path={constant.component.privacyPolicy.url} element={<PrivacyPolicy />} />
                        <Route path="*" component={FourZeroFour} />
                    </Routes>
            }
        </React.Fragment>
    );
}

export default Router;