import localforage from 'localforage';
import constant from '../constant';

export const getDetail = async () => {
    try {
        let detail: any = await localforage.getItem(constant.key.settings);
        if (detail === null) {
            return addDetail();
        }
        return { error: null, result: detail }
    } catch (err: any) {
        return { error: err.message, result: null }
    }
}

export const addDetail = async () => {
    try {
        const detail = { currency: '₹', language: 'en', fontSize: '16' };
        let res = await localforage.setItem(constant.key.settings, detail);
        return { error: null, result: res }
    } catch (err: any) {
        return { error: err.message, result: null }
    }
}

export const updateDetail = async (model: any) => {
    try {
        let res = await localforage.setItem(constant.key.settings, model);
        return { error: null, result: res }
    } catch (err: any) {
        return { error: err.message, result: null }
    }
}