import constant from './constant';
// import $ from 'jquery';

export const setStorage = (key: string, jsonObj: any) => {
    localStorage.setItem(key, JSON.stringify(jsonObj));
}

export const getStorage = (keyName: string) => {
    const item: any = localStorage.getItem(keyName);
    return JSON.parse(item);
}

export const removeStorage = (keyName: string) => {
    localStorage.removeItem(keyName);
}

export const getAuthHeader = () => {
    const token = getStorage(constant.key.token);
    const header = {
        headers: { Authorization: "Bearer " + token }
    };
    return header;
}

export const getUploadFileHeader = () => {
    const token = getStorage(constant.key.token);
    const header = {
        headers: {
            Authorization: "Bearer " + token,
            'Content-Type': 'multipart/form-data;',
            // accept: '*/*',
            // acceptEncoding: 'gzip, deflate, br'
        }
    };
    return header;
}

export const onSetValue = (setStateFunction: any, name: string, value: any) => {
    setStateFunction((prevState: any) => {
        return ({
            ...prevState,
            [name]: { ...prevState[name], value }
        })
    });
}

export const onSetError = (setStateFunction: any, name: string, error: string) => {
    setStateFunction((prevState: any) => {
        return ({
            ...prevState,
            [name]: { ...prevState[name], error }
        })
    });
}

export const onSetOptions = (setStateFunction: any, name: string, options: any) => {
    setStateFunction((prevState: any) => {
        return ({
            ...prevState,
            [name]: { ...prevState[name], options }
        })
    });
}

export const onValidateForm = (state: any, setStateFunction: any) => {
    const st = JSON.parse(JSON.stringify(state));
    let status = true;

    for (let key in st) {
        if (st.hasOwnProperty(key) && st[key]) {
            const name = st[key].name;
            const required = st[key].required;
            const value = st[key].value;
            // const type = st[key].type;
            // console.log(name, required, value, type);
            if (required) {
                if (value === null || value === undefined) {
                    onSetError(setStateFunction, name, 'This field is required');
                    status = false;
                    continue;
                }

                if (value.length === 0) {
                    // console.log(name, required, value, type);
                    onSetError(setStateFunction, name, 'This field is required');
                    status = false;
                    continue;
                } else {
                    onSetError(setStateFunction, name, '');
                    continue;
                }
            }
        }
    }
    return status;
}

export const onChange = (context: any, name: string, newValue: any, callback?: any) => {
    context.setState({ [name]: { ...context.state[name], value: newValue } }, callback && callback);
}

export const onChangePromise = (context: any, name: string, newValue: any) => {
    try {
        context.setState({ [name]: { ...context.state[name], value: newValue } }, () => {
            return Promise.resolve(true);
        });
    } catch (err) {
        return Promise.reject(err);
    }
}

export const setOptions = (context: any, name: string, value: any, callback?: any) => {
    context.setState({ [name]: { ...context.state[name], options: value } }, callback && callback);
}

export const setOptionsPromise = (context: any, name: string, value: any) => {
    try {
        context.setState({ [name]: { ...context.state[name], options: value } }, () => {
            return Promise.resolve(true);
        });
    } catch (err) {
        return Promise.reject(err);
    }
}

export const setError = (context: any, name: string, error: string, callback?: any) => {
    context.setState({ [name]: { ...context.state[name], error } }, callback && callback);
}

export const setErrorPromise = (context: any, name: string, error: string) => {
    try {
        context.setState({ [name]: { ...context.state[name], error } }, () => {
            return Promise.resolve(true);
        });
    } catch (err) {
        return Promise.reject(err);
    }
}

export const setRequired = (context: any, name: string, required: boolean, callback?: any) => {
    context.setState({ [name]: { ...context.state[name], required } }, callback && callback);
}

export const setRequiredPromise = (context: any, name: string, required: boolean) => {
    try {
        context.setState({ [name]: { ...context.state[name], required } }, () => {
            return Promise.resolve(true);
        });
    } catch (err) {
        return Promise.reject(err);
    }
}


export const sleep = (seconds: any) => {
    // console.log('going to sleep', new Date().toTimeString());
    return new Promise(resolve => setTimeout(resolve, seconds * 1000));
}

export const dynamicSort = (property: any) => {
    var sortOrder = 1;
    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a: any, b: any) {
        /* next line works with strings and numbers, 
         * and you may want to customize it to your needs
         */
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}

export const getTotalSeconds = (time: string) => {
    if (time) {
        const parts: any = time.split(':');
        return parts[0] * 3600 + parts[1] * 60 + parts[2];
    } else {
        return 0;
    }
}

//#region dynamic control utils
export const onControlChange = (e: any, context: any) => {
    const name = parseFloat(e.target.name);
    const value = e.target.value;
    const clonedControlList: any = JSON.parse(JSON.stringify(context.state.controlList));

    clonedControlList.forEach((element: any) => {
        if (element.id === name) {
            element.value = value;
        }
    });
    context.setState({ controlList: clonedControlList });
}

export const onControlCheckboxChange = (e: any, context: any) => {
    const name = parseFloat(e.target.name);
    const value = e.target.value;
    const checked = e.target.checked;
    const clonedControlList: any = JSON.parse(JSON.stringify(context.state.controlList));

    clonedControlList.forEach((element: any) => {
        if (element.id === name) {
            let existingValueArr = element.value.split(',');
            if (existingValueArr.length === 1 && existingValueArr[0] === "") {
                existingValueArr = [];
            }

            const index = existingValueArr.indexOf(value);
            if (checked && index === -1) {
                existingValueArr.push(value);
            } else {
                existingValueArr.splice(index, 1);
            }

            element.value = existingValueArr.join(',');
        }
    });
    context.setState({ controlList: clonedControlList });
}

// export const toggleSectionVisibility = (e: any) => {
//     const section = e.target.dataset.section;
//     const handleSelector = `.section-handle[data-section='${section}']`;
//     const handleElement = $(handleSelector).first();
//     const handleText = handleElement.text();

//     const sectionBodySelector = `.section-body[data-section='${section}']`;
//     const sectionBodyElement = $(sectionBodySelector).first();

//     if (handleText === '-') {
//         handleElement.text('+')
//         sectionBodyElement.hide();
//     } else {
//         handleElement.text('-')
//         sectionBodyElement.show();
//     }
// }
//#endregion

export const getDateDiffInHHMMSS = (milliseconds: number) => {
    let diff = milliseconds;
    let ms = diff % 1000;
    diff = (diff - ms) / 1000;
    let s = diff % 60;
    diff = (diff - s) / 60;
    let m = diff % 60;
    diff = (diff - m) / 60;
    let h = diff;

    let ss = s <= 9 && s >= 0 ? `0${s}` : s;
    let mm = m <= 9 && m >= 0 ? `0${m}` : m;
    let hh = h <= 9 && h >= 0 ? `0${h}` : h;

    const duration = `${hh}:${mm}:${ss}`;
    return duration;
}

// old and new date object are javascript date
export const getDateDiff = (oldDate: any, newDate: any) => {
    // get total seconds between the times
    let delta = Math.abs(oldDate - newDate) / 1000;

    // calculate (and subtract) whole days
    const days = Math.floor(delta / 86400);
    delta -= days * 86400;

    // calculate (and subtract) whole hours
    const hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    // calculate (and subtract) whole minutes
    const minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    // what's left is seconds
    const seconds = Math.floor(delta % 60);  // in theory the modulus is not required

    return {
        days: days < 10 ? `0${days}` : days,
        hours: hours < 10 ? `0${hours}` : hours,
        minutes: minutes < 10 ? `0${minutes}` : minutes,
        seconds: seconds < 10 ? `0${seconds}` : seconds
    }
}

export const getUniqueArray = (a: any) => [...new Set(a.map((o: any) => JSON.stringify(o)))].map((s: any) => JSON.parse(s))

export const readFileAsync = (file: any) => {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();

        reader.onload = (e: any) => {
            resolve(e.target.result);
        };

        reader.onerror = reject;

        // reader.readAsArrayBuffer(file);
        reader.readAsDataURL(file);
    })
}

export const getLocale = (language: string) => {
    const json = require(`./locale/${language}.json`)
    return json;
}

export const setHTMLFontSize = (fontSize: string) => {
    document.getElementsByTagName("body")[0].style.fontSize = `${fontSize}px`;
}