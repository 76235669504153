import { Modal, ModalHeader, ModalBody } from 'reactstrap';

interface IPropType {
    css: any,
    backdrop: boolean,
    onCancel: any,
    title: any,
    children: any
}
function ModalWindow(props: IPropType) {
    const { title, children, css, backdrop, onCancel } = props;
    const backdropAttribute = backdrop ? 'static' : true;
    const toggle = () => {
        onCancel();
    };

    return (
        <Modal isOpen={true} toggle={toggle} className={css} backdrop={backdropAttribute}>
            <ModalHeader toggle={toggle}>{title}</ModalHeader>
            <ModalBody>
                {children}
            </ModalBody>
        </Modal>
    );
}

export default ModalWindow;