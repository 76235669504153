import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { currencyAtom, languageAtom } from "../../atom/SettingsAtom";
import constant from '../../constant';
import { getLocale } from "../../utils";
import EditDetailButton from './EditDetailButton';

interface IPropType {
    detail: any,
    css: any,
    showFooter: boolean
}
function CardView(props: IPropType) {
    const currencySymbol = useRecoilValue(currencyAtom);
    const language = useRecoilValue(languageAtom);
    const locale = getLocale(language);

    const { detail, css, showFooter } = props;
    const { id, name, description, borrowed, returned } = detail;
    const userTransactionURL = constant.component.userTransaction.url.replace(':id', id);
    const balance = borrowed - returned;

    return (
        <div className={`card ${css}`}>
            <div className="card-body p-3">
                <div className="row">
                    <div className="col">
                        <h5 className={`font-weight-bold mb-0 text-danger`}>{currencySymbol} {borrowed.toLocaleString()}</h5>
                        <small>{locale.lbl_borrowed}</small>
                    </div>
                    <div className="col text-center">
                        <h5 className={`font-weight-bold mb-0 text-success`}>{currencySymbol} {returned.toLocaleString()}</h5>
                        <small>{locale.lbl_returned}</small>
                    </div>
                    <div className="col text-right">
                        <h5 className={`font-weight-bold mb-0 text-warning`}>{currencySymbol} {balance > 0 ? (balance).toLocaleString() : '0'}</h5>
                        <small>{locale.lbl_balance}</small>
                    </div>
                </div>
            </div>
            {
                showFooter && <div className="card-footer p-3">
                    <div className="row">
                        <div className="col">
                            <EditDetailButton id={id} css="btn btn-link p-0">{locale.btn_edit}</EditDetailButton>
                        </div>
                        <div className="col text-right">
                            <Link to={userTransactionURL}>{name}</Link>
                            <small className="d-block">{description}</small>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default CardView;