import { Link } from "react-router-dom";
import moment from "moment";
import constant from "../../constant";
import EditDetailButton from './EditDetailButton';
import { currencyAtom } from "../../atom/SettingsAtom";
import { useRecoilValue } from "recoil";

interface IPropType {
    detail: any,
    css: string,
    showUserDetail: boolean,
    onSubmitComplete: any
}

function CardView(props: IPropType) {
    const currencySymbol = useRecoilValue(currencyAtom);

    const { detail, css, showUserDetail } = props;
    const { id,
        contactId, contactName, contactDescription,
        description, createdOn, amount, transactionTypeId
    } = detail;
    const amountCSS = transactionTypeId === constant.transactionType.borrowed.id ? "text-danger" : "text-success";
    const userTransactionURL = constant.component.userTransaction.url.replace(':id', contactId);

    return (
        <div className={`card ${css}`}>
            <div className="card-body p-3">
                <div className="row">
                    <div className="col">
                        <h5 className={`font-weight-bold mb-0 ${amountCSS}`}>{currencySymbol} {amount.toLocaleString()}</h5>
                    </div>
                    <div className="col text-right">
                        <small>{moment(createdOn).format(constant.formatting.dateTimeFormat)}</small>
                    </div>
                </div>
                <small className="d-block">{description}</small>
            </div>
            <div className="card-footer p-3">
                <div className="row">
                    <div className="col">
                        <EditDetailButton onSubmitComplete={() => props.onSubmitComplete()} id={id} css="btn btn-link p-0">Edit</EditDetailButton>
                    </div>
                    <div className="col text-right">
                        {
                            showUserDetail && <>
                                <Link to={userTransactionURL}>{contactName}</Link>
                                <small className="d-block">{contactDescription}</small>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardView;