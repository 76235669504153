import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { jsPDF } from "jspdf";
import 'jspdf-autotable'
import moment from 'moment';
import ReactGA from 'react-ga4';

import constant from '../../constant';
import Header from "../../component/common/Header";
import CardView from "../../component/transaction/CardView";
import ContactCardView from "../../component/contact/CardView";
import FloatingAddButton from "../../component/transaction/FloatingAddButton";

import { getUserTransactionList } from "../../action/TransactionAction";
import { getDetail } from '../../action/ContactAction';
import { dynamicSort } from '../../utils';


function List() {

    const sortOptions = [{ id: '-createdOnUnix', name: 'Sort Dated Desc', default: true }, { id: '-amount', name: 'Amount Desc' }];
    const defaultSortOption: any = sortOptions.find((i: any) => i.default);
    const [sortBy, setSortBy]: any = useState(defaultSortOption.id);
    const [keyword, setKeyword]: any = useState('');

    let { id } = useParams();
    const [detail, setDetail]: any = useState(undefined);
    const [list, setList]: any = useState([]);
    const listToRender = getListToRender();

    useEffect(() => {
        document.title = constant.component.userTransaction.title;
        ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);
        ReactGA.pageview(window.location.pathname + window.location.search);
        
        onLoadList();
    }, [])

    return (
        <div className="container-fluid">
            <Header />
            <FloatingAddButton onSubmitComplete={() => onLoadList()} />
            {
                detail && <div className="mt-3">
                    <h5 className="mt-3 mb-0 font-weight-bold">{detail.name}</h5>
                    <small>{detail.description}</small>
                    <ContactCardView detail={detail} css="mt-3" showFooter={false} />
                </div>
            }
            <hr />
            {renderFilterSection()}
            <div className="row mb-10">
                <div className="col-md-12">
                    {
                        list.length === 0 && <div className="text-center">
                            No Records Found
                            </div>
                    }
                    {
                        listToRender.map((item: any, index: number) => {
                            return <div key={index}>
                                <CardView onSubmitComplete={() => onLoadList()} css="mb-3" detail={item} showUserDetail={false} />
                            </div>
                        })
                    }
                </div>
                
            </div>
        </div>
    )

    function renderFilterSection() {
        return (
            <div className="row mb-3">
                <div className="col">
                    <input type="text" className="form-control" placeholder="Search..." onChange={(e: any) => setKeyword(e.target.value)} />
                </div>
                <div className="col">
                    <select className="form-control" onChange={(e: any) => setSortBy(e.target.value)} value={sortBy}>
                        {
                            sortOptions.map((item: any, index: number) => {
                                return <option key={index} value={item.id}>{item.name}</option>
                            })
                        }
                    </select>
                </div>
                <div className="col-12 text-right mt-3">
                    <button type="button" className="btn btn-link p-0 btn-sm" onClick={() => onDonwloadPDF()}>Download PDF</button>
                </div>
            </div>

        )
    }

    function getListToRender() {
        let _list = list;
        if (keyword.length > 0) {
            _list = list.filter((i: any) => {
                const lowerCaseKeyword = keyword.toLowerCase();
                const lowerCaseContactName = i.contactName.toLowerCase();
                const lowerCaseContactDescription = i.contactDescription.toLowerCase();
                const lowerCaseAmount = i.amount.toString();
                const lowerCaseDescription = i.description.toLowerCase();
                const lowerCaseDate = moment(i.createdOn).format(constant.formatting.dateTimeFormat).toLowerCase();

                if (lowerCaseContactName.indexOf(lowerCaseKeyword) > -1) {
                    return true;
                } else if (lowerCaseContactDescription.indexOf(lowerCaseKeyword) > -1) {
                    return true;
                } else if (lowerCaseAmount.indexOf(lowerCaseKeyword) > -1) {
                    return true;
                } else if (lowerCaseDescription.indexOf(lowerCaseKeyword) > -1) {
                    return true;
                } else if (lowerCaseDate.indexOf(lowerCaseKeyword) > -1) {
                    return true;
                } else {
                    return false;
                }
            })
        }
        _list.sort(dynamicSort(sortBy));
        return _list;
    }

    function onLoadList() {
        getDetail(id).then((res: any) => {
            if (res.error) {
                alert(res.error);
            } else {
                setDetail(res.result);
            }
        })
        getUserTransactionList(id).then((res: any) => {
            if (res.error) {
                alert(res.error);
            } else {
                setList(res.result);
            }
        })
    }

    function onDonwloadPDF() {
        const list = getListToRender();

        const doc: any = new jsPDF();

        doc.setFontSize(18)
        doc.text(detail.name, 14, 22)
        doc.setFontSize(11)
        doc.setTextColor(100)

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize
        var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
        // var text = doc.splitTextToSize(`Balance: ${detail.balance.toLocaleString(config.locale.locale)}`, pageWidth - 35, {})
        var text = doc.splitTextToSize(`Report generated by ${constant.app.name} App`, pageWidth - 35, {});
        doc.text(text, 14, 30)


        const dataArray = [];
        for (let i = 0; i < list.length; i++) {
            const item = list[i];
            dataArray.push([
                `${i + 1}`,
                `${item.description}`,
                `${moment(item.createdOn).format(constant.formatting.dateTimeFormat)}`,
                `${item.transactionTypeId === constant.transactionType.borrowed.id ? 'Borrowed (CR)' : 'Returned (DR)'}`,
                `${item.amount.toLocaleString()}`,
            ]);
        }
        doc.autoTable({
            head: [['#', 'Description', 'Date', 'Transaction Type', 'Amount']],
            body: dataArray,
            startY: 40,
            showHead: 'firstPage',
            theme: 'grid'
        })

        doc.save(`${detail.name.trim().replace(/ /g, '-')}-${moment().format('YYYY-MM-DD')}.pdf`);
    }
}

export default List;