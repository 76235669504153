import Header from './../../component/common/Header';
function ComponentName() {

    return (
        <div className="container-fluid">
            <Header />
            <h5 className="mt-3">Privacy Policy</h5>
            <hr />
            <div className="row">
                <div className="col-md-12 mb-3">
                    <div className="">
                        <b>1. BACKGROUND</b>
                        <p>1.1 This document (“Privacy Policy”) is an electronic record in terms of: (i) Information Technology Act, 2000; (ii) the rules framed there under as applicable; and (iii) the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.</p>
                        <p>1.2 This Privacy Policy is published in accordance with the provisions of Information Technology Act, 2000 and the rules made thereunder that require publishing the rules and regulations, privacy policy and terms of use for access or usage of our Platform udhaarkhata.in (“Website”) and our mobile application Udhaar Khata (“Application”). For the purposes of this Privacy Policy, Platform and Application shall be collectively referred to as “Platform”.</p>
                        <p>1.3 The Platform is owned, registered and operated by “Vikara Services Private Limited” (“Company”), a private limited company, incorporated under the provisions of the Companies Act, 2013 and having its registered office at Unit No. 402, 4th Floor, Prestige Feroze, No.74, Sampangi Ramaswamy Temple Street (Earlier Cunningham Road), Bengaluru, Karnataka 560052.
                            <p>1.4 This Privacy Policy covers: (i) the type of information collected from the users through the Platform including sensitive personal data or information; (ii) the purpose, means and modes of usage of such information; and (iii) how and to whom such information which has been collected will be disclosed.</p>
                            <p>1.5 For the purpose of this Privacy Policy, wherever the context so requires “you“, “your” or “user” shall mean any natural person who uses the Platform. The term “we”, “us”, “our” shall mean the Company. All the other defined terms shall have the same meaning as provided for under the Terms of Use, published on the Platform here (“Terms of Use”).</p>
                            <p>1.6 We reserve the right to make changes to this Privacy Policy at any time. Any such modifications will become effective immediately upon posting to the Platform and your continued use of the Platform and/or the Services (as defined in the Terms of Use) constitutes your agreement to such modifications. You agree to periodically review the current version of the Privacy Policy as posted on the Platform. If you do not agree with the terms of this Privacy Policy, please do not use the Platform and/or the Services.</p></p>

                        <b>2. INFORMATION THAT WE COLLECT</b>
                        <p>Information that is collected as you use the Platform and its Services includes the following:</p>
                        <p>2.1. Personal Information as defined under Section 2(i) of the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011 (“SPI Rules”).</p>

                        <b>3. HOW WE USE THE INFORMATION</b>
                        <p>3.1. We will use the information that we collect for the following amongst other purposes related to the Services:</p>
                        <p>(a) We will use your name in order to customise all our communications sent to you.</p>
                        <p>(b) Your E-mail address will be used to communicate the following:</p>
                        <ol>
                            <li>Confirmation of your subscription to the Company’s newsletter and delivery of the newsletter to your e-mail ID;</li>
                            <li>Promotional messages regarding the Services offered by the Company from time to time; and</li>
                            <li>Any other communication that we wish to send to you in relation to the Platform/Services.</li>
                        </ol>
                        <p>(c) To track your usage of the Services;</p>
                        <p>(d) To improve the quality, features and functionality of the Services;</p>
                        <p>(e) To improve the security of the Services;</p>
                        <p>(f) To back up our systems and allow for disaster recovery; and</p>
                        <p>(g) As may be necessary to enforce the terms of this Privacy Policy or the Terms of Use.</p>

                        <b>4. DISCLOSURE OF THE INFORMATION</b>
                        <p>4.1. The Company does not sell, trade, or rent or disclose your Personal Information to any third party. However, the Company cannot fully ensure that such information will not be disclosed to third parties. For example, we may be legally obligated to disclose information to the government or third parties under certain circumstances or third parties may circumvent the Company’s security measures to unlawfully intercept or access transmissions or private communications, or an error may occur in the administration of the Platform. In the unlikely event that the Company needs to investigate or resolve possible problems or inquiries, we may, and you authorize the Company to, disclose any information about you to government officials as permitted by Applicable Law.</p>
                        <p>4.2. We reserve the right to disclose any Personal Information required by Applicable Law and when we believe, at our sole discretion that disclosure is necessary to protect our rights, protect someone from injury and/or to comply with a judicial proceeding, court order, or legal process served on our Platform.</p>
                        <p>4.3. If the Company becomes involved in a merger, acquisition, or any form of sale of some or all of its assets, personal information will be transferred to the new entity for the continued performance of Services.</p>
                        <p>4.4. Notwithstanding the provisions of Applicable Law, we may disclose your Personal Platform if: (a) we have received your consent for such disclosure; (b) when we believe in good faith that disclosure is necessary to protect our rights; (c) to protect your rights and safety; or (d) to investigate fraud or respond to a government, judicial or other legal requests.</p>

                        <b>5. INFORMATION RETENTION</b>
                        <p>5.1. We retain any Personal Information and Sensitive Personal Information that you may have provided to us, for as long as you engage our Services on the Platform, and for a reasonable time thereafter.</p>

                        <b>6. COOKIE POLICY</b>
                        <p>6.1. The Platform uses temporary cookies, which are files that your web browser puts on your system when you visit the Platform, to store certain information. The information collected through these cookies is used by us for the technical administration of the Platform, research and development, and to improve the quality of our Services.</p>
                        <p>6.2. We may use third party cookies to track visitor behaviour and to improve the quality of our services. However, such cookies shall not store any kind of personal information, nor shall such information be disclosed to any third party.</p>
                        <p>6.3. These cookies are intended to be automatically cleared or deleted when the user quits the browser application. You are encouraged to use the “clear cookies” functionality of your browser to ensure such clearing/deletion, since it is impossible for us to guarantee, predict or provide for the behaviour of your system.</p>
                        <p>6.3. The information we collect with cookies can be used for Advertising purpose directly or indirectly.</p>

                        <b>7. INFORMATION ON PUBLIC FORUMS</b>
                        <p>7.1. When you use certain features on the Platform like any discussion forums, or if you post any information, including Personal Information, through comments, messages, User Feedback, such information will be available to all users and the public in general. All such sharing of information will be done at your risk. Please note that if you disclose Personal Information when you make a public post, the same may become publicly available and will be subject to the terms of this Privacy Policy.</p>

                        <b>8. THIRD PARTY LINKS</b>
                        <p>8.1. The Platform may contain links to other Platforms (“Linked Sites“). The Linked Sites are not under the control of the Platform. We are not responsible for the content of any Linked Site, including, without limitation to, any link contained in a Linked Site, or any changes or updates to a Linked Site.</p>
                        <p>8.2. The Company does not intend the Linked Sites to be referrals to, endorsements of, or affiliations with the linked entities.</p>
                        <p>8.3. On accessing the Linked Sites, you shall be governed by the terms of use, privacy policy and such other additional policies of the Linked Sites. You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, advertising, products, services or other materials available on or through any Linked Sites or for any errors, defamatory content, libel, slander, omissions, falsehoods, obscene content, pornographic material, or any profanity contained therein.</p>

                        <b>9. SECURITY PROCEDURES</b>
                        <p>9.1. We have implemented industry standard security policies, rules and technical measures, as required under Section 8 of the SPI Rules in order to protect any kind of personal sensitive information that we have under our control from unauthorized access. You should know, however, that the Company cannot fully eliminate such risks.</p>

                        <b>10. GOVERNING LAW AND JURISDICTION</b>
                        <p>10.1. This Privacy Policy shall be governed by and constructed in accordance with the laws of India, without reference to conflict of laws principles. The courts in Bangalore shall have exclusive jurisdiction to determine any disputes arising in relation to, or under, the terms of this Privacy Policy. You agree to submit to the jurisdiction of the courts in Bangalore and agree to waive any and all objections to the exercise of jurisdiction over the parties by such courts.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ComponentName;